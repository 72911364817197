<template>
  <el-form
    :label-position="'left'"
    label-width="80px"
    @submit.prevent="onSubmit"
    style="margin: 20px; width: 60%; min-width: 800px"
  >
    <el-form-item label="subjectCourseId">
      <el-input v-model="choiceQuestion.subjectCourseId"> </el-input>
    </el-form-item>
    <el-form-item label="id">
      <el-input v-model="choiceQuestion.id" disabled> </el-input>
    </el-form-item>
    <el-form-item label="真题序号">
      <el-input v-model="choiceQuestion.idx"> </el-input>
    </el-form-item>
    <el-form-item label="题干">
      <aidaFeedEditor
        :openMd="true"
        :openHtml="true"
        :openCos="true"
        :openVod="false"
        :openPractice="false"
        v-model="choiceQuestion.title"
      ></aidaFeedEditor>
    </el-form-item>
    <el-form-item label="选项个数">
      <el-input v-model="contentCnt"></el-input>
    </el-form-item>
    <el-form-item label="选项">
      <el-form-item v-for="(item, index) in contentList" :key="index" :label="index | capitalize">
        <el-input v-model="contentList[index]" class="choice-item"></el-input>
      </el-form-item>
    </el-form-item>
    <el-form-item></el-form-item>
    <el-form-item label="正确答案个数">
      <el-input v-model="refAnswerCnt"></el-input>
    </el-form-item>
    <el-form-item label="答案">
      <el-form-item v-for="(item, index) in refAnswerList" :key="index" :label="index | capitalize">
        <el-input v-model="refAnswerList[index]" class="choice-item"></el-input>
      </el-form-item>
    </el-form-item>

    <el-form-item>
      <el-divider></el-divider>
    </el-form-item>
    <el-form-item label="材料个数">
      <el-input v-model="materialCnt"></el-input>
    </el-form-item>
    <el-form-item label="材料id">
      <el-form-item v-for="(item, index) in materialList" :key="index" :label="index | capitalize">
        <el-input v-model="materialList[index]" class="choice-item"></el-input>
      </el-form-item>
    </el-form-item>

    <el-form-item>
      <el-divider></el-divider>
    </el-form-item>
    <el-form-item label="考点个数">
      <el-input v-model="keypointCnt"></el-input>
    </el-form-item>
    <el-form-item label="考点id">
      <el-form-item v-for="(item, index) in keypointList" :key="index" :label="index | capitalize">
        <el-input v-model="keypointList[index]" class="choice-item"></el-input>
      </el-form-item>
    </el-form-item>

    <el-form-item label="解析">      
      <aidaFeedEditor
        :openMd="true"
        :openHtml="true"
        :openCos="true"
        :openVod="false"
        :openPractice="false"
        v-model="choiceQuestion.solution"
      ></aidaFeedEditor>
    </el-form-item>

    <el-form-item label="来源">
      <el-input v-model="choiceQuestion.source"></el-input>
    </el-form-item>
    <el-form-item label="省份">
      <el-input v-model="choiceQuestion.province"></el-input>
    </el-form-item>
    <el-form-item label="年份">
      <el-input v-model="choiceQuestion.year"></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onAddChoice">保存</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { MarkdownFileType } from "@/util/EnumUtil";
import aidaFeedEditor from "@/component/aidaFeedEditor";
import msg from "@/util/msg.js";
//import { alertMessage } from "@/util/alertMessageUtil.js";
import { tikuApi } from "@/api/tikuApi";

export default {
  components: {
    aidaFeedEditor,
  },
  data() {
    return {
      pageStatus: "add",
      contentCnt: 4,
      contentList: ["第一个选项,都是md格式", "第二个选项", "第三个选项", "第四个选项"],
      materialCnt: 1,
      materialList: [""],
      keypointCnt: 1,
      keypointList: [""],
      refAnswerCnt: 1,
      refAnswerList: [0],

      choiceQuestion: {
        subjectCourseId: 1,
        id: 0,
        type: 0,
        title: [
          {
            unitType: MarkdownFileType.MARKDOWN,
            unitId: 0,
            index: 0,
            content: "",
            fileName: "",
            url: "",
            markDown: {
              content: "题目内容",
            },
          },
        ],
        content: ["第一个选项,都是md格式", "第二个选项", "第三个选项", "第四个选项"],
        material: [""],
        refAnswer: [0],
        solution: [
          {
            unitType: MarkdownFileType.MARKDOWN,
            unitId: 0,
            index: 0,
            content: "",
            fileName: "",
            url: "",
            markDown: {
              content: "题目的解析",
            },
          },
        ],
        keypoint: [""],
        source: "",
        year: "0",
        province: "",
      },
    };
  },
  methods: {
    onAddChoice() {
      this.choiceQuestion.keypoint = this.keypointList;
      this.choiceQuestion.material = this.materialList;
      this.choiceQuestion.content = this.contentList;
      this.choiceQuestion.refAnswer = this.refAnswerList;

      if (!this.validate()) {
        msg.warn("检查哪个字段没填，不允许有空内容");
        return;
      }

      let tikuQuestion = {
        subjectCourseId: this.choiceQuestion.subjectCourseId,
        id: this.choiceQuestion.id,
        type: this.choiceQuestion.type,
        choiceQuestion: this.choiceQuestion,
        blankQuestion: null,
      };
      tikuApi
        .addChoice(tikuQuestion)
        .then((ret) => {
          let { code: code, msg: msgStr, data: data } = ret;
          if (code == 0) {
            msg.succeed("保存成功");
            console.log(data, msgStr);
            if (this.pageStatus == "add") {
              this.$router.push({
                name: "aida-tiku-choice-add",
                query: {
                  id: data.id,
                  subjectCourseId: data.subjectCourseId,
                },
              });
            }
          } else {
            msg.error("保存失败");
          }
        })
        .catch((err) => {
          console.log(err);
          msg.error("保存失败");
        });
    },

    //修改数组，让vue重新渲染
    contentArrayChange(newVal, arrType) {
      let arr = [];
      if (arrType == "content") {
        arr = this.contentList;
      } else if (arrType == "material") {
        arr = this.materialList;
      } else if (arrType == "keypoint") {
        arr = this.keypointList;
      } else if (arrType == "refAnswer") {
        arr = this.refAnswerList;
      }

      const len = arr.length;
      if (newVal > len) {
        const cnt = newVal - len;
        for (let i = 0; i < cnt; i++) {
          arr.push("");
        }
      } else if (newVal < len) {
        const cnt = len - newVal;
        for (let i = 0; i < cnt; i++) {
          arr.splice(arr.length - 1, 1);
        }
      }
      console.log("....", arr);
    },

    validate() {
      let arrLenList = [
        this.choiceQuestion.content.length,
        this.choiceQuestion.material.length,
        this.choiceQuestion.keypoint.length,
        this.choiceQuestion.refAnswer.length,
      ];
      let cntList = [this.contentCnt, this.materialCnt, this.keypointCnt, this.refAnswerCnt];
      for (let i = 0; i < arrLenList.length; i++) {
        if (arrLenList[i] != cntList[i]) {
          return false;
        }
      }
      let arrList = [
        this.choiceQuestion.content,
        this.choiceQuestion.material,
        this.choiceQuestion.keypoint,
        this.choiceQuestion.refAnswer,
      ];
      for (let i = 0; i < arrList.length; i++) {
        for (let val of arrList[i]) {
          if (val === null || val === undefined || val === "") {
            console.log(arrList[i], val);
            return false;
          }
        }
      }
      return true;
    },
  },
  mounted() {
    //this.contentCnt = this.choiceQuestion.content.length;
    //this.materialCnt = this.choiceQuestion.material.length;
    const id = this.$route.query.id;
    const subjectCourseId = this.$route.query.subjectCourseId;
    if (id && id > 0 && subjectCourseId && subjectCourseId > 0) {
      this.pageStatus = "edit";
      tikuApi.get(subjectCourseId, id).then((ret) => {
        console.log(ret);
        let { code: code, data: data } = ret;
        if (code == 0) {
          this.choiceQuestion = data.choiceQuestion;
          console.log("question", this.choiceQuestion);

          if (this.choiceQuestion.content) {
            this.contentCnt = this.choiceQuestion.content.length;
            this.contentList = this.choiceQuestion.content;
          } else {
            this.contentCnt = 0;
            this.contentList = [];
          }
          if (this.choiceQuestion.material) {
            this.materialCnt = this.choiceQuestion.material.length;
            this.materialList = this.choiceQuestion.material;
          } else {
            this.materialCnt = 0;
            this.materialList = [];
          }
          if (this.choiceQuestion.keypoint) {
            this.keypointCnt = this.choiceQuestion.keypoint.length;
            this.keypointList = this.choiceQuestion.keypoint;
          } else {
            this.keypointCnt = 0;
            this.keypointList = [];
          }
          if (this.choiceQuestion.refAnswer) {
            this.refAnswerCnt = this.choiceQuestion.refAnswer.length;
            this.refAnswerList = this.choiceQuestion.refAnswer;
          } else {
            this.refAnswerCnt = 0;
            this.refAnswerList = [];
          }
        }
      });
    }
  },
  filters: {
    capitalize: function(val) {
      //return String.fromCharCode(97 + val);
      return val.toString();
    },
  },
  watch: {
    contentCnt: function(newVal) {
      this.contentArrayChange(newVal, "content");
    },
    materialCnt: function(newVal) {
      this.contentArrayChange(newVal, "material");
    },
    keypointCnt: function(newVal) {
      this.contentArrayChange(newVal, "keypoint");
    },
    refAnswerCnt: function(newVal) {
      this.contentArrayChange(newVal, "refAnswer");
    },
  },
};
</script>

<style lang="less" scoped>
.choice-item {
  margin-bottom: 5px;
}
</style>
